import { parseAsArrayOf, parseAsString, parseAsStringEnum, useQueryStates } from 'nuqs';

const OAUTH_SCOPES = ['offline_access', 'accounts', 'transactions', 'profile', 'openid', 'email'] as const;
export type OAuthScope = (typeof OAUTH_SCOPES)[number];

const oAuthFormParsers = {
  submitFormUrl: parseAsString,
  abortUrl: parseAsString,
  missingScope: parseAsArrayOf(parseAsStringEnum([...OAUTH_SCOPES]), ' ').withDefault([]),
  requestedScope: parseAsArrayOf(parseAsStringEnum([...OAUTH_SCOPES]), ' ').withDefault([]),
  clientLogoUri: parseAsString,
};

export const useOAuthFormParams = () => useQueryStates(oAuthFormParsers)[0];
